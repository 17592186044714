.App {
  text-align: center;
}

 * {
  text-decoration:none;
  vertical-align:top;
  margin:0;
  padding:0
 }
 *,
 :after,
 :before {
  box-sizing:border-box
 }
 textarea,
 select,
 input:not([type=checkbox]):not([type=radio]),
 button {
  outline:none;
  -webkit-appearance:none
  
 }
 a:hover {
  transition:all .6s ease
 }
 body {
  overflow-x:hidden;
  width:100vw
  
 }
 .rr {
  flex-direction:row-reverse
 }
 .up {
  text-transform:uppercase
 }
 img {
  max-width:100%;
  height:auto
 }
 
 .btn,
 .tc {
  text-align:center
 }
 .tr {
  text-align:right
 }
 .tl,
 th,
 td {
  text-align:left
 }
 td,
 th {
  padding:8px 10px
 }
 .text-black {
  color:#000
 }
 html {
  -ms-overflow-style:scrollbar
 }
 .container {
  max-width:1200px
 }
 .container,
 .container-fluid {
  
  margin-right:auto;
  margin-left:auto;
  position:relative;
  padding-right:15px;
  padding-left:15px;
  width:90%;

 }
 #menu-toggler,
 .hidden,
 .tab-content,
 [hidden],
 input.hidden:not([type=checkbox]):not([type=radio]),
 .sub-toggle {
  display:none
 }
 #logo,
 .block,
 .nav-link,
 .submenu li,
 header a,
 input:not([type=checkbox]):not([type=radio]),
 label,
 main,
 select,
 textarea,
 .panel.show {
  display:block
 }
 .inl,
 .nav-item,
 header li {
  display:inline-block;
  position:relative
 }
 
 strong {
  font-weight:bolder
 }
 
 h1,
 h2 {
  line-height:1.15em;
  text-align:left
 }
 #details .item,
 h1,
 h2,
 h3,
 main ul,
 ol,
 p,
 table {
  margin-bottom:15px
 }
p {
  text-align:left;
}
 header .ui-title,
 header ul,
 main ul:last-child,
 ol:last-child,
 p:last-child,
 .mb-0 {
  margin-bottom:0
 }
 main ul,
 ol {
  margin-left:18px
 }
 #hero img,
 .w-100,
 figure img,
 header,
 table {
  width:100%
 }
 .w-50 {
  width:50%
 }
 #menu-toggler {
  width:45px;
  height:45px;
  background:0 0;
  border:none;
  z-index:1500
 }
 
 
 .left {
  float:left
 }
 .right {
  float:right
 }
 .clear {
  clear:both
 }
 .mid {
  vertical-align:middle
 }
 .bot {
  vertical-align:bottom
 }
 .btn,
 .table td {
  vertical-align:middle
 }
 .bg-white {
  background-color:#fff
 }
 .bg-light {
  background-color:#f2f2f2
 }
 .btn {
  padding:7px 15px;
  display:inline-block;
  border-radius:4px;
  font-size:1rem;
  border:1px solid #999;
  color:#000
 }
 #menu-toggler,
 .accordion,
 .btn,
 a,
 ul.tabs li,
 button {
  cursor:pointer
 }

 input:not([type=checkbox]):not([type=radio]),
 select,
 textarea {
  width:100%;
  font-size:1rem;
  padding:.375rem .75rem;
  color:#495057;
  border-radius:5px;
  line-height:1.4rem
 }
 .pt-2,
 .pt-3,
 .py-2,
 .py-3 {
  padding-top:1rem
 }
 label {
  margin-bottom:4px
 }

 .flex,
 .cell,
 .row {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
 }
 .jr {
  justify-content:flex-end
 }
 .pr-2,
 .pr-3,
 .px-2,
 .px-3 {
  padding-right:1rem
 }
 .pl-2,
 .pl-3,
 .px-2,
 .px-3 {
  padding-left:1rem
 }
 .row {
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  margin-right:-15px;
  margin-left:-15px
 }
 .col-image,
 .col-menu,
 .col-pagemaintext {
  position:relative;
  width:100%;
  min-height:1px;
  padding-right:15px;
  padding-left:15px;
  -webkit-box-flex:0
 }
 .col-pagemaintext {
  -ms-flex:0 0 66.6666%;
  flex:0 0 66.6666%;
  max-width:66.6666%
 }
 .col-menu {
  -ms-flex:0 0 50%;
  flex:0 0 50%;
  max-width:50%
 }
 .col-image {
  -ms-flex:0 0 33.3333%;
  flex:0 0 33.3333%;
  max-width:33.3333%
 }
 
 

 @media screen and (max-width:1200px) {
  .r2 {
   -ms-flex:0 0 100%;
   flex:0 0 100%;
   max-width:100%
  }
  .r22 {
   -ms-flex:0 0 50%;
   flex:0 0 50%;
   max-width:50%
  }
  .r23 {
   -ms-flex:0 0 33.3333%;
   flex:0 0 33.3333%;
   max-width:33.3333%
  }
  .rh2 {
   display:none
  }
 }
 @media screen and (max-width:1000px) {
  .r1 {
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
   }
   .r12 {
    -ms-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
   }
   .r13 {
    -ms-flex:0 0 33.3333%;
    flex:0 0 33.3333%;
    max-width:33.3333%
   }
   .rh1 {
    display:none
   }
 }
 @media screen and (max-width:750px) {
  .r7 {
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
   }
   .r72 {
    -ms-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
   }
   .rh7 {
    display:none
   }
 }
 @media screen and (max-width:550px) {
  .r5 {
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
   }
   .r52 {
    -ms-flex:0 0 50%;
    flex:0 0 50%;
    max-width:50%
   }
   .rh5 {
    display:none
   }
 }
 @media screen and (max-width:400px) {
  .r4 {
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
   }
 }
 @media screen and (max-width:360px) {
  .r3 {
    -ms-flex:0 0 100%;
    flex:0 0 100%;
    max-width:100%
   }
 }

 table {
  border-collapse:collapse
 }

 
 @-ms-viewport {
  width:device-width
 }

 @media all and (max-width:700px) {
  


  
 }
 @media all and (max-width:710px) {
  
 }
 body {
  font:400 16px OpenSans,sans-serif
 }
 h1 {
  font:400 2.6rem "Lucida Handwriting"
 }
 h2 {
  font:700 1.1rem 'OpenSans Bold'
 }
 a,
 h1 {
  color:#186545
 }
 .nav-link {
  font-size:1.2rem
 }


 @media screen and (min-width:1500px) {
  
 }
 
 @media screen and (min-width:1200px) {
  .active .nav-link {
   color:#186545
  }
  nav {
   text-align:right
  }
  .nav-item {
   padding:15px
  }
  .nav-link {
   padding:6px 2px;
   border-top:3px solid transparent
   
  }
  .active .nav-link,
  .nav-item:hover .nav-link {
   border-color:#186545
  }
  .pagecontent {
   background:#ededed;
   padding:45px
  }
  .pagecontent.rr {
   padding:45px 30px
  }
 }
 @media screen and (max-width:1200px) {
  .nav-item,
  #menu-toggler {
   display:block
  }

  .nav-link {
   border-top:1px solid rgba(255,255,255,.2);
   padding:10px 15px;
   color:#fff
  }
  .active .nav-link {
   color:#000
  }
  header .container {
   width:100%
  }
  header {
   margin-bottom:3rem
  }
 }
 @media screen and (max-width:750px) {
  .mbr {
   margin-bottom:1.5rem
  }
  h1 {
   font-size:1.8rem
  }
  .gallery a {
   text-align:center
  }
}